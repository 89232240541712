<script>
export default {
  name: "PostAdoptReport",
  data: () => ({
    entities: [
      {
        "id": 14,
        "name": "Serena Flowers Law",
        "ein": null,
        "firm_type": {
          "id": 2,
          "type": "Managing law firm"
        },
        "primary_user": {
          "id": 272,
          "email": "serena.clark@gmail.com",
          "role": "Managing attorney",
          "name": "Serena Flowers",
          "first": "Serena",
          "last": "Flowers"
        }
      },
      {
        "id": 15,
        "name": "Serena Flowers Law",
        "ein": null,
        "firm_type": {
          "id": 2,
          "type": "Managing law firm"
        },
        "primary_user": {
          "id": 272,
          "email": "serena.clark@gmail.com",
          "role": "Managing attorney",
          "name": "Serena Flowers",
          "first": "Serena",
          "last": "Flowers"
        }
      },
    ],
    filteredEntities: [],
    loading: false,
    searchValue: '',
  }),
  methods: {
    searchEntity(value) {
      this.filteredEntities = this.entities.filter((entity) => {
        return entity.name.toLowerCase().startsWith(value.toLowerCase())
      })
    },
    handleItemClick(item) {
      this.$router.push(`/post-adopt-report/${item.id}`)
    },
  },
  watch: {
    searchValue(newValue) {
      if (newValue)
        this.searchEntity(newValue)
      else
        this.filteredEntities = [...this.entities]
    },
  },
  mounted() {
    this.filteredEntities = [...this.entities]
  }

};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div>
          <v-text-field
            outlined
            hide-details
            label="Search..."
            prepend-inner-icon="mdi-magnify"
            v-model="searchValue"
          ></v-text-field>
        </div>
      </v-col>
      <v-col>
        <v-list three-line>
          <v-col class="text-subtitle-1 text-center" cols="12" v-if="loading"> Loading... </v-col>
          <v-col cols="12">
            <v-progress-linear
              :active="loading"
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>

          <template v-for="(item) in filteredEntities">
            <v-list-item
              :key="item.title"
              @click="handleItemClick(item)"
              v-ripple
            >
              <v-avatar color="blue" class="mr-3">
                <v-icon dark> mdi-account-circle</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
                <v-list-item-subtitle>{{ item.firm_type.type }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>