<script>
export default {
  name: "IntroEmail",
  data:  () => ({
    clients: [
      {
        id: 1,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      },
      {
        id: 2,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      },
      {
        id: 3,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      },
      {
        id: 4,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      },
      {
        id: 5,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      }
    ],
    headers: [
      {
        text: 'Client',
        value: 'client',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      }
    ]
  }),
  methods: {
    handleRowClick(item) {
      this.$router.push(`/clients/${item.id}/emails`)
    }
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              @click:row="handleRowClick"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>