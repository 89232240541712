<script>
export default {
  name: "AdoptionReview",
  data:  () => ({
    search: '',
    selectedClients: [],
    headers: [
      {
        text: 'Client',
        filterable: true,
        value: 'client'
      },
      {
        text: 'EIN',
        filterable: true,
        value: 'ein'
      },
      {
        text: 'Legal Name',
        filterable: true,
        value: 'legal_name'
      }
    ],
    clients: [
      {
        id: 1,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      },
      {
        id: 2,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      },
      {
        id: 3,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      },
      {
        id: 4,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      },
      {
        id: 5,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores'
      }
    ]
  }),
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              :search="search"
              show-select
              v-model="selectedClients"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="!selectedClients.length" color="primary">Accept</v-btn>
            <v-btn :disabled="!selectedClients.length" color="primary">Reject</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>