<script>
import LawService from "@/services/law.service";

export default {
  name: "AdoptionTool",
  data: () => ({
    firms: [],
    donors: [],
    receivers: [],
    donorSearchValue: '',
    receiverSearchValue: '',
    firmsLoading: false,
    selectedDonorId: undefined,
    selectedReceiverId: undefined,
    share: 0,
  }),
  methods: {
    async getAllLaws() {
      this.firmsLoading = true;

      try {
        this.firms = await LawService.getLaws();
        this.donors = [...this.firms]
        this.receivers = [...this.firms]
      } finally {
        this.firmsLoading = false;
      }
    },
    selectDonor(id) {
      if (this.selectedReceiverId !== id)
        this.selectedDonorId = id
    },
    selectReceiver(id) {
      if (this.selectedDonorId !== id)
        this.selectedReceiverId = id
    },
    searchDonor(value) {
      this.donors = this.firms.filter((firm) => {
        return firm.name.toLowerCase().startsWith(value.toLowerCase())
      })
    },
    searchReceiver(value) {
      this.receivers = this.firms.filter((firm) => {
        return firm.name.toLowerCase().startsWith(value.toLowerCase())
      })
    }
  },
  created() {
    this.getAllLaws();
  },
  watch: {
    donorSearchValue(newValue) {
      if (newValue)
        this.searchDonor(newValue)
      else
        this.donors = [...this.firms]
    },
    receiverSearchValue(newValue) {
      if (newValue)
        this.searchReceiver(newValue)
      else
        this.receivers = [...this.firms]
    },
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col>
        <div>
          <v-text-field
            outlined
            label="Search Donor"
            prepend-inner-icon="mdi-magnify"
            v-model="donorSearchValue"
          ></v-text-field>
        </div>
        <div style="background-color: #D4DAEF" class="pa-4 font-weight-bold">Donor</div>
        <div
          v-for="firm in donors"
          class="pa-4"
          :class="{'selected': selectedDonorId === firm.id, 'invalid': selectedReceiverId === firm.id}"
          style="border-bottom: 1px solid #E5E7EB; cursor: pointer"
          @click="selectDonor(firm.id)"
        >
          {{ firm.name }}
        </div>
      </v-col>
      <v-col>
        <div>
          <v-text-field
            outlined
            label="Search Receiver"
            prepend-inner-icon="mdi-magnify"
            v-model="receiverSearchValue"
          ></v-text-field>
        </div>
        <div style="background-color: #D4DAEF" class="pa-4 font-weight-bold">Receiver</div>
        <div
          v-for="firm in receivers"
          class="pa-4"
          style="border-bottom: 1px solid #E5E7EB; cursor: pointer"
          :class="{'selected': selectedReceiverId === firm.id, 'invalid': selectedDonorId === firm.id}"
          @click="selectReceiver(firm.id)"
        >
          {{ firm.name }}
        </div>
      </v-col>
      <v-col>
        <div style="background-color: #D4DAEF" class="pa-4 font-weight-bold mb-7">Percentage Share</div>
        <div>
          <v-text-field
            outlined
            suffix="%"
            type="number"
            max="100"
            min="1"
            v-model="share"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.selected {
  background-color: #F2F5FF;
}
.invalid {
  cursor: not-allowed !important;
  opacity: 0.4;
}
</style>