<script>
export default {
  name: "IntroLetter",
  data:  () => ({
    selectedLetters: [],
    search: '',
    category: 'new',
    headers: [
      {
        text: 'Name',
        filterable: true,
        value: 'name'
      },
      {
        text: 'Email',
        filterable: true,
        value: 'email'
      },
      {
        text: 'Company Name',
        filterable: true,
        value: 'company_name'
      },
      {
        text: 'Address',
        filterable: true,
        value: 'address'
      },
    ],
    letters: [
      {
        id: 1,
        name: 'Bryan',
        email: 'bryan@gmail.com',
        company_name: 'Legacy Estate',
        address: 'Street, state, ZIP'
      },
      {
        id: 2,
        name: 'Bryan',
        email: 'bryan@gmail.com',
        company_name: 'Legacy Estate',
        address: 'Street, state, ZIP'
      },
      {
        id: 3,
        name: 'Bryan',
        email: 'bryan@gmail.com',
        company_name: 'Legacy Estate',
        address: 'Street, state, ZIP'
      },
      {
        id: 4,
        name: 'Bryan',
        email: 'bryan@gmail.com',
        company_name: 'Legacy Estate',
        address: 'Street, state, ZIP'
      },
      {
        id: 5,
        name: 'Bryan',
        email: 'bryan@gmail.com',
        company_name: 'Legacy Estate',
        address: 'Street, state, ZIP'
      }
    ]
  }),
  watch: {
    category(newValue) {
      alert('category changed')
    }
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-row>
            <v-col class="d-flex justify-center align-center">
              <v-radio-group row v-model="category">
                <v-radio label="New Category" value="new"/>
                <v-radio label="Printed Category" value="printed" />
                <v-radio label="Shipped Category" value="shipped"/>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="letters"
              :search="search"
              show-select
              v-model="selectedLetters"
            />
          </v-card-text>
          <v-card-actions v-if="category === 'new'">
            <v-btn :disabled="!selectedLetters.length" color="primary">Print</v-btn>
          </v-card-actions>
          <v-card-actions v-else-if="category === 'printed'">
            <div class="d-flex justify-space-between align-center" style="width: 100%">
              <!-- BUTTON ?????????????????? -->
              <v-btn dense color="primary" >Letter template is available for download</v-btn>
              <v-btn color="primary">Export to CSV</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>