<script>
import AdoptionReview from "@/components/SinglePostAdoptFirm/AdoptionReview.vue";
import IntroLetter from "@/components/SinglePostAdoptFirm/IntroLetter.vue";
import IntroEmail from "@/components/SinglePostAdoptFirm/IntroEmail.vue";
import ContactInitiated from "@/components/SinglePostAdoptFirm/ContactInitiated.vue";

export default {
  name: "SingleAdoptedFirm",
  components: { AdoptionReview, IntroLetter, IntroEmail, ContactInitiated },
  data: () => ({
    entityId: "",
    component: null,
    items: [
      {
        title: "Adoption review",
        count: 34,
        component: AdoptionReview,
      },
      {
        title: "Introduction letter",
        count: 15,
        component: IntroLetter,
      },
      {
        title: "Introduction email",
        count: 5,
        component: IntroEmail
      },
      {
        title: "Contact initiated",
        count: 44,
        component: ContactInitiated
      },
      {
        title: "Document verification",
        count: 56,
      },
      {
        title: "Unresponsive",
        count: 34,
      },
      {
        title: "Unresponsive 2nd attempt",
        count: 26,
      },
      {
        title: "Unresponsive 3nd attempt",
        count: 18,
      },
      {
        title: "Problematic",
        count: 10,
      },
      {
        title: "Waiting to be filed",
        count: 11,
      },
      {
        title: "Filed with IRS",
        count: 11,
      },
      {
        title: "Collecting transcript",
        count: 80,
      },
      {
        title: "Transcript verified",
        count: 58,
      },
    ],
  }),
  methods: {
    loadInitialComponent() {
      const component = this.$route.hash.substring(1)

      switch (component) {
        case 'adoption-review':
          this.component = AdoptionReview
          break;
        case 'intro-letter':
          this.component = IntroLetter
          break;
        case 'intro-email':
          this.component = IntroEmail
          break;
        case 'contact-initiated':
          this.component = ContactInitiated
          break;
        default:
          this.component = null
      }
    },
    loadComponent(component) {
      this.component = component
      window.scroll(0, 0)
    }
  },
  mounted() {
    this.entityId = this.$route.params.entityId;
    if (this.$route.hash)
      this.loadInitialComponent()
  },
};
</script>

<template>
  <div>
    <v-row>
      <v-row v-if="component">
        <v-col cols="12" class="d-flex align-center" style="gap: 10px">
          <v-icon @click="component = null" color="black">mdi-arrow-left</v-icon>
          <div class="text-h6">{{ component.name.replace(/([a-z])([A-Z])/g, "$1 $2") }}</div>
        </v-col>
        <v-col cols="12">
          <component :is="component" />
        </v-col>
      </v-row>
      <v-col v-else cols="12" md="6">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                @click="loadComponent(item.component)" cols="12" v-for="item in items" :key="item.title"
                v-ripple
                style="border-bottom: 1px solid #00000012; cursor: pointer;"
              >
                <v-sheet class="d-flex align-center justify-space-between">
                  <div class="text-h6">{{ item.title }}</div>
                  <v-chip small color="primary">{{ item.count }}</v-chip>
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>