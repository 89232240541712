<script>
export default {
  name: "ContactInitiated",
  data: () => ({
    clients: [
      {
        id: 1,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores',
        date: '10/7/2024'
      },
      {
        id: 2,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores',
        date: '10/7/2024'
      },
      {
        id: 3,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores',
        date: '10/7/2024'
      },
      {
        id: 4,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores',
        date: '10/7/2024'
      },
      {
        id: 5,
        client: 'Alberto Flores',
        ein: '5644535353',
        legal_name: 'Alberto Flores',
        date: '10/7/2024'
      }
    ],
    headers: [
      {
        text: 'Client',
        value: 'client',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date contact initiated',
        value: 'date',
      }
    ],
    selectedClients: []
  }),
  methods: {
    confirmContacts() {
      // TODO: pitay Velik ili Kal za api v 9:00 sutrinta

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Contact(s) confirmed!', duration: 6000})
      this.selectedClients = []
    }

  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              show-select
              v-model="selectedClients"
            />
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn color="primary" @click="confirmContacts" :disabled="!selectedClients.length">Confirm contact </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>