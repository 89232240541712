<script>
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientService from "@/services/clients.service";

export default {
  name: "Emails",
  components: { ClientHeader },
  data: () => ({
    client: null
  }),
  methods: {
    getCurrentClient(){
      ClientService.getOneClient(this.clientId).then(res=>{
        this.client = res
      })
    },
  },
  created() {
    this.clientId = this.$route.params.clientId
    this.getCurrentClient()
  },
};
</script>

<template>
  <div>
    <ClientHeader :client="client" />
    <v-container>
      <v-row>
        <v-col>
          <v-btn color="primary">GENERATE INTRO EMAIL</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

</style>